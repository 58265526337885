/*
Copyright 2019 - 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { createRef, KeyboardEvent, SyntheticEvent } from "react";
import EMOJI_REGEX from "emojibase-regex";
import {
    // EventStatus,
    EventType,
    IContent,
    IEventRelation,
    IMentions,
    MatrixEvent,
    MsgType,
    RelationType,
    Room,
    THREAD_RELATION_TYPE,
} from "matrix-js-sdk/src/matrix";
import { DebouncedFunc, throttle } from "lodash";
import { logger } from "matrix-js-sdk/src/logger";
import { Composer as ComposerEvent } from "@matrix-org/analytics-events/types/typescript/Composer";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import EditorModel from "matrix-react-sdk/src/editor/model";
import {
    containsEmote,
    htmlSerializeIfNeeded,
    startsWith, stripEmoteCommand,
    stripPrefix,
    textSerialize,
    unescapeMessage,
} from "matrix-react-sdk/src/editor/serialize";
// import BasicMessageComposer, { REGEX_EMOTICON } from "matrix-react-sdk/src/components/views/rooms/BasicMessageComposer";
import { CommandPartCreator, Part, PartCreator, SerializedPart, Type } from "matrix-react-sdk/src/editor/parts";
import { findEditableEvent } from "matrix-react-sdk/src/utils/EventUtils";
// import SendHistoryManager from "matrix-react-sdk/src/SendHistoryManager";
// import { CommandCategories } from "matrix-react-sdk/src/SlashCommands";
// import ContentMessages from "matrix-react-sdk/src/ContentMessages";
import { MatrixClientProps, withMatrixClientHOC } from "matrix-react-sdk/src/contexts/MatrixClientContext";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { containsEmoji } from "matrix-react-sdk/src/effects/utils";
import { CHAT_EFFECTS } from "matrix-react-sdk/src/effects";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { getKeyBindingsManager } from "matrix-react-sdk/src/KeyBindingsManager";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { RoomPermalinkCreator } from "matrix-react-sdk/src/utils/permalinks/Permalinks";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import { decorateStartSendingTime, sendRoundTripMetric } from "matrix-react-sdk/src/sendTimePerformanceMetrics";
import RoomContext, { TimelineRenderingType } from "matrix-react-sdk/src/contexts/RoomContext";
import DocumentPosition from "matrix-react-sdk/src/editor/position";
import { ComposerType } from "matrix-react-sdk/src/dispatcher/payloads/ComposerInsertPayload";
// import { getSlashCommand, isSlashCommand, runSlashCommand, shouldSendAnyway } from "matrix-react-sdk/src/editor/commands";
import { KeyBindingAction } from "matrix-react-sdk/src/accessibility/KeyboardShortcuts";
import { PosthogAnalytics } from "matrix-react-sdk/src/PosthogAnalytics";
import { addReplyToMessageContent } from "matrix-react-sdk/src/utils/Reply";
import { doMaybeLocalRoomAction } from "matrix-react-sdk/src/utils/local-room";
import { Caret } from "matrix-react-sdk/src/editor/caret";
import { IDiff } from "matrix-react-sdk/src/editor/diff";
import { getBlobSafeMimeType } from "matrix-react-sdk/src/utils/blobs";
// CTalk imported
import { ERoomMessageEventType } from "@ctalk/interfaces/rooms/IRoomMessageEvent";
import { hasLinksNewMessage } from "@ctalk/helpers/RoomEventInfoHelper";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";
import EditorStateTransfer from "matrix-react-sdk/src/utils/EditorStateTransfer";
import { filterBoolean } from "matrix-react-sdk/src/utils/arrays";
import { parseEvent } from "matrix-react-sdk/src/editor/deserialize";
import SendMessageHistoryManager, {
    IHistoryItems,
} from "@ctalk/managers/SendMessageHistoryManager";
import { PREVIEW_ACTION_TYPE } from "@ctalk/enums/composer.enum";

import BasicMessageComposer, { REGEX_EMOTICON } from "./BasicMessageComposer";
import { RoomEventInfoStore } from "../../../stores/RoomEventInfoStore";
import ContentMessages from "../../../ContentMessages";

/**
 * Build the mentions information based on the editor model (and any related events):
 *
 * 1. Search the model parts for room or user pills and fill in the mentions object.
 * 2. If this is a reply to another event, include any user mentions from that
 *    (but do not include a room mention).
 *
 * @param sender - The Matrix ID of the user sending the event.
 * @param content - The event content.
 * @param model - The editor model to search for mentions, null if there is no editor.
 * @param replyToEvent - The event being replied to or undefined if it is not a reply.
 * @param editedContent - The content of the parent event being edited.
 */
export function attachMentions(
    sender: string,
    content: IContent,
    model: EditorModel | null,
    replyToEvent: MatrixEvent | undefined,
    editedContent: IContent | null = null,
): void {
    // We always attach the mentions even if the home server doesn't yet support
    // intentional mentions. This is safe because m.mentions is an additive change
    // that should simply be ignored by incapable home servers.

    // The mentions property *always* gets included to disable legacy push rules.
    const mentions: IMentions = (content["m.mentions"] = {});

    const userMentions = new Set<string>();
    let roomMention = false;

    // If there's a reply, initialize the mentioned users as the sender of that
    // event + any mentioned users in that event.
    if (replyToEvent) {
        userMentions.add(replyToEvent.sender!.userId);
        // TODO What do we do if the reply event *doeesn't* have this property?
        // Try to fish out replies from the contents?
        const userIds = replyToEvent.getContent()["m.mentions"]?.user_ids;
        if (Array.isArray(userIds)) {
            userIds.forEach((userId) => userMentions.add(userId));
        }
    }

    // If user provided content is available, check to see if any users are mentioned.
    if (model) {
        // Add any mentioned users in the current content.
        for (const part of model.parts) {
            if (part.type === Type.UserPill) {
                userMentions.add(part.resourceId);
            } else if (part.type === Type.AtRoomPill) {
                roomMention = true;
            }
        }
    }

    // Ensure the *current* user isn't listed in the mentioned users.
    userMentions.delete(sender);

    // Finally, if this event is editing a previous event, only include users who
    // were not previously mentioned and a room mention if the previous event was
    // not a room mention.
    if (editedContent) {
        // First, the new event content gets the *full* set of users.
        const newContent = content["m.new_content"];
        const newMentions: IMentions = (newContent["m.mentions"] = {});

        // Only include the users/room if there is any content.
        if (userMentions.size) {
            newMentions.user_ids = [...userMentions];
        }
        if (roomMention) {
            newMentions.room = true;
        }

        // Fetch the mentions from the original event and remove any previously
        // mentioned users.
        const prevMentions = editedContent["m.mentions"];
        if (Array.isArray(prevMentions?.user_ids)) {
            prevMentions!.user_ids?.forEach((userId) => userMentions.delete(userId));
        }

        // If the original event mentioned the room, nothing to do here.
        if (prevMentions?.room) {
            roomMention = false;
        }
    }

    // Only include the users/room if there is any content.
    if (userMentions.size) {
        mentions.user_ids = [...userMentions];
    }
    if (roomMention) {
        mentions.room = true;
    }

    /**
     * CTalk added: fix deprecated mentions: remove m.mentions if not mentions user
     */
    content = checkAndRemoveMentions(content);
    if (editedContent) {
        content = checkAndRemoveMentions(content['m.new_content']);
    }

}

/**
 * CTalk added: remove "m.mentions" > room
 * Due to the new rendering upgrade source, if there is a key 'm.mentions', '@room' cannot be rendered.
 * @param content IContent
 */
function checkAndRemoveMentions(content: IContent): IContent{
    delete content["m.mentions"];
    return content;
}

// Merges favouring the given relation
export function attachRelation(content: IContent, relation?: IEventRelation): void {
    if (relation) {
        content["m.relates_to"] = {
            ...(content["m.relates_to"] || {}),
            ...relation,
        };
    }
}

// exported for tests
export function createMessageContent(
    sender: string,
    model: EditorModel,
    replyToEvent: MatrixEvent | undefined,
    relation: IEventRelation | undefined,
    permalinkCreator?: RoomPermalinkCreator,
    includeReplyLegacyFallback = true,
): IContent {
    /* CTalk hide this for send messages (/me)
    const isEmote = containsEmote(model);
    if (isEmote) {
        model = stripEmoteCommand(model);
    }
     */
    if (startsWith(model, "//")) {
        model = stripPrefix(model, "/");
    }
    model = unescapeMessage(model);

    const body = textSerialize(model);

    const content: IContent = {
        // CTalk updated msgtype for remove Emote (/me)
        // msgtype: isEmote ? MsgType.Emote : MsgType.Text,
        msgtype: MsgType.Text,
        body: body,
    };
    const formattedBody = htmlSerializeIfNeeded(model, {
        forceHTML: !!replyToEvent,
        useMarkdown: SettingsStore.getValue("MessageComposerInput.useMarkdown"),
    });
    if (formattedBody) {
        content.format = "org.matrix.custom.html";
        content.formatted_body = formattedBody;
    }

    // Build the mentions property and add it to the event content.
    attachMentions(sender, content, model, replyToEvent);

    attachRelation(content, relation);
    if (replyToEvent) {
        addReplyToMessageContent(content, replyToEvent, {
            permalinkCreator,
            includeLegacyFallback: includeReplyLegacyFallback,
        });
    }

    return content;
}

// exported for tests
export function isQuickReaction(model: EditorModel): boolean {
    const parts = model.parts;
    if (parts.length == 0) return false;
    const text = textSerialize(model);
    // shortcut takes the form "+:emoji:" or "+ :emoji:""
    // can be in 1 or 2 parts
    if (parts.length <= 2) {
        const hasShortcut = text.startsWith("+") || text.startsWith("+ ");
        const emojiMatch = text.match(EMOJI_REGEX);
        if (hasShortcut && emojiMatch && emojiMatch.length == 1) {
            return emojiMatch[0] === text.substring(1) || emojiMatch[0] === text.substring(2);
        }
    }
    return false;
}

interface ISendMessageComposerProps extends MatrixClientProps {
    room: Room;
    placeholder?: string;
    permalinkCreator?: RoomPermalinkCreator;
    relation?: IEventRelation;
    replyToEvent?: MatrixEvent;
    editingEvent?: MatrixEvent; // CTalk added
    disabled?: boolean;
    onChange?(model: EditorModel): void;
    includeReplyLegacyFallback?: boolean;
    toggleStickerPickerOpen: () => void;
}

// CTalk added
enum EActionType  {
    EDIT = "edit",
    REPLY = "reply"
}

// CTalk added
interface IStage {
    actionType?: PREVIEW_ACTION_TYPE;
    replyParts: Part[];
    currentItems: IHistoryItems | null;
    isInitialized: boolean;
}

export class SendMessageComposer extends React.Component<ISendMessageComposerProps, IStage> {
    public static contextType = RoomContext;
    public context!: React.ContextType<typeof RoomContext>;

    private readonly prepareToEncrypt?: DebouncedFunc<() => void>;
    private readonly editorRef = createRef<BasicMessageComposer>();
    private model: EditorModel;
    private currentlyComposedEditorState: SerializedPart[] | null = null;
    private dispatcherRef: string;
    // CTalk hide this
    // private sendHistoryManager: SendHistoryManager;
    // CTalk added
    private historyManager: SendMessageHistoryManager;
    private ckClient = CTalkClientPeg.get();
    public static defaultProps = {
        includeReplyLegacyFallback: true,
    };

    public constructor(props: ISendMessageComposerProps, context: React.ContextType<typeof RoomContext>) {
        super(props, context);
        this.context = context; // otherwise React will only set it prior to render due to type def above

        if (this.props.mxClient.isCryptoEnabled() && this.props.mxClient.isRoomEncrypted(this.props.room.roomId)) {
            this.prepareToEncrypt = throttle(
                () => {
                    this.props.mxClient.prepareToEncrypt(this.props.room);
                },
                60000,
                { leading: true, trailing: false },
            );
        }

        // CTalk hide this
        // window.addEventListener("beforeunload", this.saveStoredEditorState);
        // CTalk added
        window.addEventListener("beforeunload", this.setMessageHistoryInStorageWillUnmount);

        const partCreator = new CommandPartCreator(this.props.room, this.props.mxClient);
        const parts = this.restoreStoredEditorState(partCreator) || [];
        this.model = new EditorModel(parts, partCreator);
        this.dispatcherRef = dis.register(this.onAction);
        // CTalk hide this
        // this.sendHistoryManager = new SendHistoryManager(this.props.room.roomId, "mx_cider_history_");

        // CTalk added
        this.historyManager = new SendMessageHistoryManager(this.props.room.roomId, "mx_cider_history_");
        this.state = {
            actionType: undefined,
            replyParts: [],
            currentItems: null,
            isInitialized: false,
        };
    }

    public componentDidUpdate(prevProps: ISendMessageComposerProps): void {
        const replyingToThread = this.props.relation?.key === THREAD_RELATION_TYPE.name;
        const differentEventTarget = this.props.relation?.event_id !== prevProps.relation?.event_id;

        const threadChanged = replyingToThread && differentEventTarget;
        if (threadChanged) {
            const partCreator = new CommandPartCreator(this.props.room, this.props.mxClient);
            const parts = this.restoreStoredEditorState(partCreator) || [];
            this.model.reset(parts);
            this.editorRef.current?.focus();
        }
    }

    private onKeyDown = (event: KeyboardEvent): void => {
        // ignore any keypress while doing IME compositions
        if (this.editorRef.current?.isComposing(event)) {
            return;
        }
        const replyingToThread = this.props.relation?.key === THREAD_RELATION_TYPE.name;
        const action = getKeyBindingsManager().getMessageComposerAction(event);
        switch (action) {
            case KeyBindingAction.SendMessage:
                this.sendMessage();
                event.preventDefault();
                break;
            case KeyBindingAction.SelectPrevSendHistory:
            case KeyBindingAction.SelectNextSendHistory: {
                // Try select composer history
                // CTalk updated
                // const selected = this.selectSendHistory(action === KeyBindingAction.SelectPrevSendHistory);
                const selected = this.selectSendMessageHistory(action === KeyBindingAction.SelectPrevSendHistory);
                if (selected) {
                    // We're selecting history, so prevent the key event from doing anything else
                    event.preventDefault();
                }
                break;
            }
            case KeyBindingAction.ShowStickerPicker: {
                if (!SettingsStore.getValue("MessageComposerInput.showStickersButton")) {
                    return; // Do nothing if there is no Stickers button
                }
                this.props.toggleStickerPickerOpen();
                event.preventDefault();
                break;
            }
            case KeyBindingAction.EditPrevMessage:
                // selection must be collapsed and caret at start
                if (this.hasEditEvent()) {
                    return;
                }

                if (this.editorRef.current?.isSelectionCollapsed() && this.editorRef.current?.isCaretAtStart()) {
                    const events = this.context.liveTimeline
                        ?.getEvents()
                        .concat(replyingToThread ? [] : this.props.room.getPendingEvents());
                    const editEvent = events
                        ? findEditableEvent({
                              events,
                              isForward: false,
                              matrixClient: MatrixClientPeg.safeGet(),
                          })
                        : undefined;
                    if (editEvent) {
                        // We're selecting history, so prevent the key event from doing anything else
                        event.preventDefault();
                        // CTalk updated
                        /*
                        dis.dispatch({
                            action: Action.EditEvent,
                            event: editEvent,
                            timelineRenderingType: this.context.timelineRenderingType,
                        });
                         */
                        dis.dispatch({
                            action: PREVIEW_ACTION_TYPE.EDIT,
                            event: editEvent,
                            timelineRenderingType: this.context.timelineRenderingType,
                        });
                    }
                }
                break;
            case KeyBindingAction.CancelReplyOrEdit:
                // CTalk added
                if (this.props.editingEvent) {
                    dis.dispatch({
                        action: PREVIEW_ACTION_TYPE.EDIT,
                        event: null,
                        context: this.context.timelineRenderingType,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                }
                if (this.context.replyToEvent) {
                    dis.dispatch({
                        action: PREVIEW_ACTION_TYPE.REPLY,
                        event: null,
                        context: this.context.timelineRenderingType,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;
        }
    };

    // CTalk added
    private selectSendMessageHistory(up: boolean): boolean {
        const delta = up ? -1 : 1;
        // True if we are not currently selecting history, but composing a message
        if (this.historyManager.currentIndex === this.historyManager.history.length) {
            // We can't go any further - there isn't any more history, so nop.
            if (!up) {
                return false;
            }
            this.currentlyComposedEditorState = this.model.serializeParts();
        } else if (
            this.currentlyComposedEditorState &&
            this.historyManager.currentIndex + delta === this.historyManager.history.length
        ) {
            // True when we return to the message being composed currently
            this.model.reset(this.currentlyComposedEditorState);
            this.historyManager.currentIndex = this.historyManager.history.length;
            return true;
        }
        const { edit, reply, base } = this.historyManager.getItem(delta);
        const eventId = edit?.eventId ?? reply?.eventId;
        dis.dispatch({
            action: PREVIEW_ACTION_TYPE.REPLY,
            event: eventId ? this.props.room.findEventById(eventId) : null,
            context: this.context.timelineRenderingType,
        });
        const parts =  edit?.parts ?? reply?.parts ?? base?.parts ?? [];
        if (parts) {
            this.model.reset(parts);
            this.editorRef.current?.focus();
        }
        return true;
    }

    /* CTalk hide this
    // we keep sent messages/commands in a separate history (separate from undo history)
    // so you can alt+up/down in them
    private selectSendHistory(up: boolean): boolean {
        const delta = up ? -1 : 1;
        // True if we are not currently selecting history, but composing a message
        if (this.sendHistoryManager.currentIndex === this.sendHistoryManager.history.length) {
            // We can't go any further - there isn't any more history, so nop.
            if (!up) {
                return false;
            }
            this.currentlyComposedEditorState = this.model.serializeParts();
        } else if (
            this.currentlyComposedEditorState &&
            this.sendHistoryManager.currentIndex + delta === this.sendHistoryManager.history.length
        ) {
            // True when we return to the message being composed currently
            this.model.reset(this.currentlyComposedEditorState);
            this.sendHistoryManager.currentIndex = this.sendHistoryManager.history.length;
            return true;
        }
        const { parts, replyEventId } = this.sendHistoryManager.getItem(delta);
        dis.dispatch({
            action: "reply_to_event",
            event: replyEventId ? this.props.room.findEventById(replyEventId) : null,
            context: this.context.timelineRenderingType,
        });
        if (parts) {
            this.model.reset(parts);
            this.editorRef.current?.focus();
        }
        return true;
    }
     */

    private sendQuickReaction(): void {
        const timeline = this.context.liveTimeline;
        if (!timeline) return;
        const events = timeline.getEvents();
        const reaction = this.model.parts[1].text;
        for (let i = events.length - 1; i >= 0; i--) {
            if (events[i].getType() === EventType.RoomMessage) {
                let shouldReact = true;
                const lastMessage = events[i];
                const userId = MatrixClientPeg.safeGet().getSafeUserId();
                const messageReactions = this.props.room.relations.getChildEventsForEvent(
                    lastMessage.getId()!,
                    RelationType.Annotation,
                    EventType.Reaction,
                );

                // if we have already sent this reaction, don't redact but don't re-send
                if (messageReactions) {
                    const myReactionEvents =
                        messageReactions.getAnnotationsBySender()?.[userId] || new Set<MatrixEvent>();
                    const myReactionKeys = [...myReactionEvents]
                        .filter((event) => !event.isRedacted())
                        .map((event) => event.getRelation()?.key);
                    shouldReact = !myReactionKeys.includes(reaction);
                }
                if (shouldReact) {
                    MatrixClientPeg.safeGet().sendEvent(lastMessage.getRoomId()!, EventType.Reaction, {
                        "m.relates_to": {
                            rel_type: RelationType.Annotation,
                            event_id: lastMessage.getId(),
                            key: reaction,
                        },
                    });
                    dis.dispatch({ action: "message_sent" });
                }
                break;
            }
        }
    }

    // CTalk added
    private getHtmlReplyFallback(mxEvent: MatrixEvent): string {
        const html = mxEvent.getContent().formatted_body;
        if (!html) {
            return "";
        }
        const rootNode = new DOMParser().parseFromString(html, "text/html").body;
        const mxReply = rootNode.querySelector("mx-reply");
        return (mxReply && mxReply.outerHTML) || "";
    }

    // CTalk added
    private getTextReplyFallback(mxEvent: MatrixEvent): string {
        const body: string = mxEvent.getContent().body;
        const lines = body.split("\n").map((l) => l.trim());
        if (lines.length > 2 && lines[0].startsWith("> ") && lines[1].length === 0) {
            return `${lines[0]}\n\n`;
        }
        return "";
    }

    // CTalk added
    private createEditContent(model: EditorModel, editedEvent: MatrixEvent, replyToEvent?: MatrixEvent): IContent {
        const isEmote = containsEmote(model);
        if (isEmote) {
            model = stripEmoteCommand(model);
        }
        const isReply = !!editedEvent.replyEventId;
        let plainPrefix = "";
        let htmlPrefix = "";

        if (isReply) {
            plainPrefix = this.getTextReplyFallback(editedEvent);
            htmlPrefix = this.getHtmlReplyFallback(editedEvent);
        }

        const body = textSerialize(model);

        const newContent: IContent = {
            msgtype: isEmote ? MsgType.Emote : MsgType.Text,
            body: body,
        };
        const contentBody: IContent = {
            "msgtype": newContent.msgtype,
            "body": `${plainPrefix} * ${body}`,
            "m.new_content": newContent,
        };

        const formattedBody = htmlSerializeIfNeeded(model, {
            forceHTML: isReply,
            useMarkdown: SettingsStore.getValue("MessageComposerInput.useMarkdown"),
        });
        if (formattedBody) {
            newContent.format = "org.matrix.custom.html";
            newContent.formatted_body = formattedBody;
            contentBody.format = newContent.format;
            contentBody.formatted_body = `${htmlPrefix} * ${formattedBody}`;
        }

        // Build the mentions properties for both the content and new_content.
        attachMentions(editedEvent.sender!.userId, contentBody, model, replyToEvent, editedEvent.getContent());
        attachRelation(contentBody, { rel_type: "m.replace", event_id: editedEvent.getId() });

        return contentBody;
    }

    // CTalk added
    private sendEdit = async (): Promise<void> => {
        const editedEvent = this.props.editingEvent;
        if (!editedEvent) {
            return;
        }

        PosthogAnalytics.instance.trackEvent<ComposerEvent>({
            eventName: "Composer",
            isEditing: true,
            messageType: "Text",
            inThread: !!editedEvent.getThread(),
            isReply: !!editedEvent.replyEventId,
        });

        // Replace emoticon at the end of the message
        if (SettingsStore.getValue("MessageComposerInput.autoReplaceEmoji") && this.editorRef.current) {
            const caret = this.editorRef.current.getCaret();
            const position = this.model.positionForOffset(caret.offset, caret.atNodeEnd);
            this.editorRef.current.replaceEmoticon(position, REGEX_EMOTICON);
        }
        const replyToEvent = editedEvent.replyEventId ? this.context.room?.findEventById(editedEvent.replyEventId) : undefined;
        const editContent = this.createEditContent(this.model, editedEvent, replyToEvent);
        const newContent = editContent["m.new_content"];

        const shouldSend = true;
        if (newContent?.body === "") {
            // TODO handle delete message
            // In the case of new content being empty
            // currently when the content is empty the message cannot be sent action
            // so this part of deleting the message can be improved later.
        }
        // If content is modified then send an updated event into the room
        if (this.isContentModified(newContent)) {
            const roomId = editedEvent.getRoomId()!;
            if (shouldSend) {
                const event = this.props.editingEvent;
                const threadId = event.threadRootId || null;

                this.props.mxClient.sendMessage(roomId, threadId, editContent).then(() => {
                    // Add "then" to handle check eventIdExisted in function handleMessageHasLinks
                    RoomEventInfoStore.instance.handleMessageHasLinks(event, newContent);
                });

                dis.dispatch({ action: "message_sent" });
            }
        }
        this.endEdit();
    };

    // CTalk added
    private endEdit(): void {
        this.clearMessageHistoryInStorage(EActionType.EDIT);
        // close the event editing and focus composer
        dis.dispatch({
            action: PREVIEW_ACTION_TYPE.EDIT,
            event: null,
            timelineRenderingType: this.context.timelineRenderingType,
        });
    }

    // CTalk added
    private isContentModified(newContent: IContent): boolean {
        // if nothing has changed then bail
        const oldContent = this.props.editingEvent?.getContent();
        if (
            oldContent &&
            oldContent["msgtype"] === newContent["msgtype"] &&
            oldContent["body"] === newContent["body"] &&
            oldContent["format"] === newContent["format"] &&
            oldContent["formatted_body"] === newContent["formatted_body"]
        ) {
            return false;
        }
        return true;
    }

    public async sendMessage(): Promise<void> {
        const model = this.model;

        if (model.isEmpty) {
            return;
        }
        // CTalk added
        if (this.getPreviewType() === PREVIEW_ACTION_TYPE.EDIT) {
            this.sendEdit();
            return;
        }

        const posthogEvent: ComposerEvent = {
            eventName: "Composer",
            isEditing: false,
            messageType: "Text",
            isReply: !!this.props.replyToEvent,
            inThread: this.props.relation?.rel_type === THREAD_RELATION_TYPE.name,
        };
        if (posthogEvent.inThread && this.props.relation!.event_id) {
            const threadRoot = this.props.room.findEventById(this.props.relation!.event_id);
            posthogEvent.startsThread = threadRoot?.getThread()?.events.length === 1;
        }
        PosthogAnalytics.instance.trackEvent<ComposerEvent>(posthogEvent);

        // Replace emoticon at the end of the message
        if (SettingsStore.getValue("MessageComposerInput.autoReplaceEmoji")) {
            const indexOfLastPart = model.parts.length - 1;
            const positionInLastPart = model.parts[indexOfLastPart].text.length;
            this.editorRef.current?.replaceEmoticon(
                new DocumentPosition(indexOfLastPart, positionInLastPart),
                REGEX_EMOTICON,
            );
        }

        const replyToEvent = this.props.replyToEvent;
        let shouldSend = true;
        let content: IContent | null = null;

        /* Ctalk hide this.
        // When /command, because array slash commands = []
        // isSlashCommand cannot found in this array -> open dialog help -> it's unnecessary
        if (!containsEmote(model) && isSlashCommand(this.model)) {
            const [cmd, args, commandText] = getSlashCommand(this.model);
            if (cmd) {
                const threadId =
                    this.props.relation?.rel_type === THREAD_RELATION_TYPE.name ? this.props.relation?.event_id : null;

                let commandSuccessful: boolean;
                [content, commandSuccessful] = await runSlashCommand(
                    MatrixClientPeg.safeGet(),
                    cmd,
                    args,
                    this.props.room.roomId,
                    threadId ?? null,
                );
                if (!commandSuccessful) {
                    return; // errored
                }

                if (
                    content &&
                    [CommandCategories.messages as string, CommandCategories.effects as string].includes(cmd.category)
                ) {
                    // Attach any mentions which might be contained in the command content.
                    attachMentions(this.props.mxClient.getSafeUserId(), content, model, replyToEvent);
                    attachRelation(content, this.props.relation);
                    if (replyToEvent) {
                        addReplyToMessageContent(content, replyToEvent, {
                            permalinkCreator: this.props.permalinkCreator,
                            // Exclude the legacy fallback for custom event types such as those used by /fireworks
                            includeLegacyFallback: content.msgtype?.startsWith("m.") ?? true,
                        });
                    }
                } else {
                    shouldSend = false;
                }
            } else {
                const sendAnyway = await shouldSendAnyway(commandText);
                // re-focus the composer after QuestionDialog is closed
                dis.dispatch({
                    action: Action.FocusAComposer,
                    context: this.context.timelineRenderingType,
                });
                // if !sendAnyway bail to let the user edit the composer and try again
                if (!sendAnyway) return;
            }
        }
        */

        if (isQuickReaction(model)) {
            shouldSend = false;
            this.sendQuickReaction();
        }

        if (shouldSend) {
            const { roomId } = this.props.room;
            if (!content) {
                content = createMessageContent(
                    this.props.mxClient.getSafeUserId(),
                    model,
                    replyToEvent,
                    this.props.relation,
                    this.props.permalinkCreator,
                    this.props.includeReplyLegacyFallback,
                );
            }
            // don't bother sending an empty message
            if (!content.body.trim()) return;

            if (SettingsStore.getValue("Performance.addSendMessageTimingMetadata")) {
                decorateStartSendingTime(content);
            }

            const threadId =
                this.props.relation?.rel_type === THREAD_RELATION_TYPE.name ? this.props.relation.event_id : null;

            const prom = doMaybeLocalRoomAction(
                roomId,
                (actualRoomId: string) => this.props.mxClient.sendMessage(actualRoomId, threadId ?? null, content!),
                this.props.mxClient,
            );
            if (replyToEvent) {
                // Clear reply_to_event as we put the message into the queue
                // if the send fails, retry will handle resending.
                dis.dispatch({
                    action: PREVIEW_ACTION_TYPE.REPLY,
                    event: null,
                    context: this.context.timelineRenderingType,
                });

                // CTalk added
                this.clearMessageHistoryInStorage(EActionType.REPLY, true);
            }
            dis.dispatch({ action: "message_sent" });
            CHAT_EFFECTS.forEach((effect) => {
                if (containsEmoji(content!, effect.emojis)) {
                    // For initial threads launch, chat effects are disabled
                    // see #19731
                    const isNotThread = this.props.relation?.rel_type !== THREAD_RELATION_TYPE.name;
                    if (isNotThread) {
                        dis.dispatch({ action: `effects.${effect.command}` });
                    }
                }
            });
            if (SettingsStore.getValue("Performance.addSendMessageTimingMetadata")) {
                prom.then((resp) => {
                    sendRoundTripMetric(this.props.mxClient, roomId, resp.event_id);
                });
            }
            // CTalk added
            prom.then((resp) => {
                if (hasLinksNewMessage(content!)) {
                    this.ckClient.createRoomMessageEvent({
                        eventId: resp.event_id,
                        roomId: roomId,
                        type: ERoomMessageEventType.LINK,
                    }).catch((e) => {
                        logger.error(e);
                    });
                }
            });
        }

        // CTalk updated
        // this.sendHistoryManager.save(model, replyToEvent);
        this.historyManager.save(model, replyToEvent);
        // clear composer
        model.reset([]);
        this.editorRef.current?.clearUndoHistory();
        this.editorRef.current?.focus();
        this.clearStoredEditorState();
        if (shouldSend && SettingsStore.getValue("scrollToBottomOnMessageSent")) {
            dis.dispatch({
                action: "scroll_to_bottom",
                timelineRenderingType: this.context.timelineRenderingType,
            });
        }
    }

    public componentWillUnmount(): void {
        dis.unregister(this.dispatcherRef);
        // CTalk hide this
        // window.removeEventListener("beforeunload", this.saveStoredEditorState);
        // this.saveStoredEditorState();

        // CTalk added
        window.removeEventListener("beforeunload", this.setMessageHistoryInStorageWillUnmount);
        this.setMessageHistoryInStorageWillUnmount();
    }

    private get editorStateKey(): string {
        let key = `mx_cider_state_${this.props.room.roomId}`;
        if (this.props.relation?.rel_type === THREAD_RELATION_TYPE.name) {
            key += `_${this.props.relation.event_id}`;
        }
        return key;
    }

    private clearStoredEditorState(): void {
        localStorage.removeItem(this.editorStateKey);
    }

    // CTalk added
    private loadPartsFromLocalStorage(partCreator: PartCreator, currentItems: IHistoryItems): Part[] | undefined {
        if (currentItems.edit?.eventId === this.props.editingEvent?.getId()) {
            try {
                const { edit,  } = currentItems;
                if (!edit) {
                    return;
                }
                const serializedParts = edit?.parts ?? [];
                const parts: Part[] = serializedParts
                    .map((p: SerializedPart) => partCreator.deserializePart(p))
                    .filter((part): part is Part => part !== undefined);
                return parts;
            } catch (e) {
                logger.error("Error parsing editing state: ", e);
            }
        }
    }

    private restoreStoredEditorState(partCreator: PartCreator): Part[] | null {
        const replyingToThread = this.props.relation?.key === THREAD_RELATION_TYPE.name;
        if (replyingToThread) {
            return null;
        }

        const json = localStorage.getItem(this.editorStateKey);
        if (json) {
            try {
                /* CTalk hide this
                const { parts: serializedParts, replyEventId } = JSON.parse(json);
                const parts: Part[] = serializedParts.map((p: SerializedPart) => partCreator.deserializePart(p));
                if (replyEventId) {
                    dis.dispatch({
                        action: "reply_to_event",
                        event: this.props.room.findEventById(replyEventId),
                        context: this.context.timelineRenderingType,
                    });
                }
                 */
                const currentItems = JSON.parse(json) as IHistoryItems;
                const { edit, reply, base } = currentItems;
                this.setState({
                    currentItems,
                })
                if (!edit && !reply && !base) {
                    return null;
                }
                const eventId = edit?.eventId ?? reply?.eventId;
                /* CTalk hide this
                if (eventId) {
                    dis.dispatch({
                        action: "reply_to_event",
                        event: this.props.room.findEventById(eventId),
                        context: this.context.timelineRenderingType,
                    });
                }
                 */
                // CTalk added
                if (eventId) {
                    dis.dispatch({
                        action: edit ? PREVIEW_ACTION_TYPE.EDIT : PREVIEW_ACTION_TYPE.REPLY,
                        event: this.props.room.findEventById(eventId),
                        context: this.context.timelineRenderingType,
                    });
                }
                const serializedParts = edit?.parts ?? reply?.parts ?? base?.parts ?? [];
                const parts: Part[] = serializedParts
                    .map((p: SerializedPart) => partCreator.deserializePart(p))
                    .filter((part): part is Part => part !== undefined);

                return parts;
            } catch (e) {
                logger.error(e);
            }
        }

        return null;
    }

    // should save state when editor has contents or reply is open
    private shouldSaveStoredEditorState = (): boolean => {
        // CTalk updated
        // return !this.model.isEmpty || !!this.props.replyToEvent;
        return !this.model.isEmpty || !!this.props.replyToEvent || !!this.props.editingEvent;
    };

    // CTalk added
    private clearMessageHistoryInStorage(type: EActionType, isSubmitted?: boolean): void {
        const currentItems = this.getCurrentItems();
        let model = this.model.clone();
        model.reset([]);
        if (type === EActionType.EDIT) {
            const editUpdated = this.historyManager.getEditDraft(model, currentItems, this.props.editingEvent);
            currentItems.edit = editUpdated?.edit ?? null;
        } else if (type === EActionType.REPLY) {
            const replyUpdated = this.historyManager.getReplyDraft(model, currentItems, this.props.replyToEvent);
            currentItems.reply = replyUpdated?.reply ?? null;
            if (!isSubmitted) {
                const base = currentItems.base;
                const isSamePartBefore =
                    (base && base?.parts.length && JSON.stringify(base.parts) === JSON.stringify(this.model.parts));
                if (isSamePartBefore) {
                    model.reset(currentItems.base?.parts ?? []);
                } else {
                    model = this.model.clone();
                }
                const baseUpdated = this.historyManager.getBaseDraft(model, currentItems);
                currentItems.base = baseUpdated?.base ?? null;
            } else {
                currentItems.base = null;
            }
        }
        this.model.reset(currentItems?.base?.parts ?? []);
        this.setState({ currentItems: currentItems });
        localStorage.setItem(this.editorStateKey, JSON.stringify(currentItems));
    }

    // CTalk added
    private setEditMessageHistoryInStorage = (currentItems: IHistoryItems): void => {
        const isEditingDifferentMessage = currentItems.edit && currentItems.edit?.eventId !== this.props.editingEvent?.getId();
        const editingEvent = currentItems.edit;
        const editUpdated = this.historyManager.getEditDraft(this.model, currentItems, this.props.editingEvent);
        currentItems.edit = editUpdated?.edit ?? null;
        let model = this.model.clone();
        const base = currentItems.base;

        const shouldResetModel =
            (base && base.parts?.length && JSON.stringify(base.parts) === JSON.stringify(this.model.parts)) ||
            isEditingDifferentMessage;
        if (shouldResetModel) {
            // If a base exists, has parts, and the parts are identical to the current model's parts,
            // reset the model with the base parts from currentItems.
            model.reset(currentItems?.base?.parts ?? []);
        } else if (!base && editingEvent && this.props.editingEvent && !this.state.isInitialized) {
            // If there's no base, an edit event is ongoing
            // and reload page or change room -> the state is not initialized,
            // reset the model with an empty array, essentially clearing it.
            model.reset([]);
        } else if (!this.state.isInitialized && base) {
            // If and reload page or change room -> the state is not initialized and a base exists,
            // reset the model with the base parts from currentItems.
            model.reset(currentItems?.base?.parts ?? []);
        }
        // Get base draft and saved to storage
        const baseUpdated = this.historyManager.getBaseDraft(model, currentItems);
        currentItems.base = baseUpdated?.base && baseUpdated?.base.parts.length ? baseUpdated?.base : null;
        if (this.props.replyToEvent && this.state.isInitialized) {
            if (baseUpdated?.base?.parts.length) {
                model.reset(baseUpdated?.base?.parts ?? []);
            } else {
                model = this.model.clone();
            }
            const replyUpdated = this.historyManager.getReplyDraft(model, currentItems, this.props.replyToEvent);
            currentItems.reply = replyUpdated?.reply ?? null;
        }
        this.setState({
            currentItems: currentItems,
            isInitialized: true,
        });
        localStorage.setItem(this.editorStateKey, JSON.stringify(currentItems));
    };

    // CTalk added
    private setMessageHistoryInStorageWillUnmount = (): void => {
        if (this.shouldSaveStoredEditorState()) {
            const currentItems = this.getCurrentItems();
            if (this.props.editingEvent || currentItems?.edit) {
                const editUpdated = this.historyManager.getEditDraft(this.model, currentItems, this.props.editingEvent);
                currentItems.edit = editUpdated?.edit ?? null;
            }
            if (this.props.replyToEvent || currentItems.reply) {
                const model = this.model.clone();
                if (this.props.editingEvent) {
                    model.reset(currentItems.reply?.parts ?? []);
                } else {
                    model.reset(this.model.parts);
                }
                const replyUpdated = this.historyManager.getReplyDraft(model, currentItems, this.props.replyToEvent);
                currentItems.reply = replyUpdated?.reply ?? null;
            }
            if (currentItems.base || (this.model.parts.length && !this.props.editingEvent && !this.props.replyToEvent) ) {
                const model = this.model.clone();
                if (JSON.stringify(currentItems.base?.parts) === JSON.stringify(this.model.parts)) {
                    model.reset(currentItems.base?.parts ?? []);
                }
                const draftUpdated = this.historyManager.getBaseDraft(model, currentItems);
                currentItems.base = draftUpdated?.base;
            }
            localStorage.setItem(this.editorStateKey, JSON.stringify(currentItems));
        } else {
            this.clearStoredEditorState();
        }
    }

    /* CTalk hide this
    private saveStoredEditorState = (): void => {
        if (this.shouldSaveStoredEditorState()) {
            const item = SendHistoryManager.createItem(this.model, this.props.replyToEvent);
            localStorage.setItem(this.editorStateKey, JSON.stringify(item));
        } else {
            this.clearStoredEditorState();
        }
    };
     */

    //CTalk added
    private getPreviewType = (): PREVIEW_ACTION_TYPE | undefined => {
        let actionType;
        if (this.props.editingEvent) {
            actionType =  PREVIEW_ACTION_TYPE.EDIT;
        } else if (this.props.replyToEvent) {
            actionType = PREVIEW_ACTION_TYPE.REPLY
        }
        this.setState({
            actionType,
        });
        return actionType;
    }

    //CTalk added
    private hasReplyEvent(): boolean {
        const json = localStorage.getItem(this.editorStateKey);
        if (!json) {
            return false;
        }
        const currentItems = JSON.parse(json) as IHistoryItems;
        return !!currentItems?.reply || !!this.props.replyToEvent;
    }

    //CTalk added
    private hasEditEvent(): boolean {
        const json = localStorage.getItem(this.editorStateKey);
        if (!json) {
            return false;
        }
        const currentItems = JSON.parse(json) as IHistoryItems;
        return !!currentItems?.edit || !!this.props.editingEvent;
    }

    private onAction = (payload: ActionPayload): void => {
        // don't let the user into the composer if it is disabled - all of these branches lead
        // to the cursor being in the composer
        if (this.props.disabled) return;

        switch (payload.action) {
            // CTalk added
            case PREVIEW_ACTION_TYPE.EDIT: {
                if (!payload.event) {
                    if (this.hasReplyEvent()) {
                        this.createReplyModel();
                        return;
                    }
                    this.clearMessageHistoryInStorage(EActionType.EDIT);
                    this.editorRef.current?.focus();
                    break;
                }
                const editState = payload.event ? new EditorStateTransfer(payload.event) : undefined;
                this.createEditorModel(editState!);
                this.editorRef.current?.focus();
                break;
            }
            // CTalk added
            case PREVIEW_ACTION_TYPE.REPLY:
                if ((payload.context ?? TimelineRenderingType.Room) === this.context.timelineRenderingType) {
                    if (!payload.event) {
                        this.clearMessageHistoryInStorage(EActionType.REPLY);
                        this.editorRef.current?.focus();
                        return;
                    }
                    const currentItems = this.getCurrentItems();
                    if (this.props.editingEvent) {
                        // Has editing event and base messge
                        if (currentItems?.base) {
                            // The reason for creating a new model without cloning
                            // because when cloning and using the reset function, it has the wrong focus
                            const partCreator = new CommandPartCreator(this.props.room, this.props.mxClient);
                            const parts: Part[] = currentItems?.base?.parts
                                .map((p: SerializedPart) => partCreator.deserializePart(p))
                                .filter((part): part is Part => part !== undefined);
                            const model = new EditorModel(parts ?? [], partCreator)
                            const replyUpdated = this.historyManager.getReplyDraft(model, currentItems, this.props.replyToEvent);
                            currentItems.reply = replyUpdated?.reply ?? null;
                        }
                    } else {
                        if (payload.isEditCanceled) {
                            return;
                        }
                        // Has reply and base message
                        const replyUpdated = this.historyManager.getReplyDraft(this.model, currentItems, this.props.replyToEvent);
                        currentItems.reply = replyUpdated?.reply ?? null;
                        const draftUpdated = this.historyManager.getBaseDraft(this.model, currentItems);
                        currentItems.base = draftUpdated?.base ?? null;
                        this.model.reset(currentItems.reply?.parts ?? []);
                        this.editorRef.current?.focus();
                    }
                    this.setState({
                        currentItems: currentItems,
                    });
                    localStorage.setItem(this.editorStateKey, JSON.stringify(currentItems));
                }
                break;
            // case "reply_to_event": // CTalk hide this
            case Action.FocusSendMessageComposer:
                if ((payload.context ?? TimelineRenderingType.Room) === this.context.timelineRenderingType) {
                    this.editorRef.current?.focus();
                }
                break;
            case Action.ComposerInsert:
                if (payload.timelineRenderingType !== this.context.timelineRenderingType) break;
                if (payload.composerType !== ComposerType.Send) break;

                if (payload.userId) {
                    this.editorRef.current?.insertMention(payload.userId);
                } else if (payload.event) {
                    this.editorRef.current?.insertQuotedMessage(payload.event);
                } else if (payload.text) {
                    this.editorRef.current?.insertPlaintext(payload.text);
                }
                break;
            case Action.UploadStarted:
                this.model.reset([]);
                this.clearStoredEditorState();
                this.editorRef.current?.focus();
                break;
        }
    };

    // CTalk added
    private getCurrentItems(): IHistoryItems {
        let currentItems = this.state.currentItems;

        if (!currentItems) {
            const json = localStorage.getItem(this.editorStateKey);
            if (json) {
                currentItems = JSON.parse(json) as IHistoryItems;
            }
        }

        if (!currentItems) {
            currentItems = {
                edit: null,
                reply: null,
                base: null,
            };
        }

        return currentItems;
    }

    // CTalk added
    private createReplyModel(): void {
        this.editorRef.current?.focus();
        const historyItems = this.getCurrentItems();
        // If reload or change room -> need to dispatch reply event
        if (!this.props.replyToEvent) {
            dis.dispatch({
                action: PREVIEW_ACTION_TYPE.REPLY,
                event: this.props.room.findEventById(historyItems.reply?.eventId ?? ''),
                context: this.context.timelineRenderingType,
                isEditCanceled: true,
            });
        }
        // Handle set data reply when cancel or send edit message
        const currentItems: IHistoryItems =  {
            reply: historyItems?.reply ?? null,
            edit: null,
            base: historyItems.base,
        };
        const replyUpdated = this.historyManager.getReplyDraft(this.model, currentItems, this.props.replyToEvent);
        this.setState({
            currentItems: replyUpdated,
        });
        localStorage.setItem(this.editorStateKey, JSON.stringify(replyUpdated));
        this.model.reset(currentItems?.reply?.parts ?? []);
    }

    // CTalk added
    private createEditorModel(editState: EditorStateTransfer): void {
        const partCreator = new CommandPartCreator(this.props.room, this.props.mxClient);
        const currentItems = this.getCurrentItems();
        let parts: Part[];
        if (editState.hasEditorState()) {
            // if restoring state from a previous editor,
            // restore serialized parts from the state
            parts = filterBoolean<Part>(editState.getSerializedParts()!.map((p) => partCreator.deserializePart(p)));
        } else {
            // otherwise, either restore serialized parts from localStorage or parse the body of the event
            const restoredParts = this.loadPartsFromLocalStorage(partCreator, currentItems);
            parts =
                restoredParts ||
                parseEvent(editState.getEvent(), partCreator, {
                    shouldEscape: SettingsStore.getValue("MessageComposerInput.useMarkdown"),
                });
        }
        // Check and set the base draft before reassigning it to the model
        this.setEditMessageHistoryInStorage(currentItems);
        this.model.reset(parts);
        this.setState({
            actionType: PREVIEW_ACTION_TYPE.EDIT,
        });
    }

    private onBlur = (): void => {
        this.setMessageHistoryInStorageWillUnmount();
    }

    private onPaste = (event: Event | SyntheticEvent, data: DataTransfer): boolean => {
        // Prioritize text on the clipboard over files if RTF is present as Office on macOS puts a bitmap
        // in the clipboard as well as the content being copied. Modern versions of Office seem to not do this anymore.
        // We check text/rtf instead of text/plain as when copy+pasting a file from Finder or Gnome Image Viewer
        // it puts the filename in as text/plain which we want to ignore.
        if (data.files.length && !data.types.includes("text/rtf")) {
            this.setMessageHistoryInStorageWillUnmount();
            ContentMessages.sharedInstance().sendContentListToRoom(
                Array.from(data.files),
                this.props.room.roomId,
                this.props.relation,
                this.props.mxClient,
                this.context.timelineRenderingType,
                !!this.props.editingEvent,
            );
            return true; // to skip internal onPaste handler
        }

        // Safari `Insert from iPhone or iPad`
        // data.getData("text/html") returns a string like: <img src="blob:https://...">
        if (data.types.includes("text/html")) {
            const imgElementStr = data.getData("text/html");
            const parser = new DOMParser();
            const imgDoc = parser.parseFromString(imgElementStr, "text/html");

            if (
                imgDoc.getElementsByTagName("img").length !== 1 ||
                !imgDoc.querySelector("img")?.src.startsWith("blob:") ||
                imgDoc.childNodes.length !== 1
            ) {
                console.log("Failed to handle pasted content as Safari inserted content");

                // Fallback to internal onPaste handler
                return false;
            }
            const imgSrc = imgDoc!.querySelector("img")!.src;

            fetch(imgSrc).then(
                (response) => {
                    response.blob().then(
                        (imgBlob) => {
                            const type = imgBlob.type;
                            const safetype = getBlobSafeMimeType(type);
                            const ext = type.split("/")[1];
                            const parts = response.url.split("/");
                            const filename = parts[parts.length - 1];
                            const file = new File([imgBlob], filename + "." + ext, { type: safetype });
                            ContentMessages.sharedInstance().sendContentToRoom(
                                file,
                                this.props.room.roomId,
                                this.props.relation,
                                this.props.mxClient,
                                this.context.replyToEvent,
                            );
                        },
                        (error) => {
                            console.log(error);
                        },
                    );
                },
                (error) => {
                    console.log(error);
                },
            );

            // Skip internal onPaste handler
            return true;
        }

        return false;
    };

    private onChange = (selection?: Caret, inputType?: string, diff?: IDiff): void => {
        // We call this in here rather than onKeyDown as that would trip it on global shortcuts e.g. Ctrl-k also
        if (diff) {
            this.prepareToEncrypt?.();
        }

        this.props.onChange?.(this.model);
    };

    private focusComposer = (): void => {
        this.editorRef.current?.focus();
    };

    public render(): React.ReactNode {
        const threadId =
            this.props.relation?.rel_type === THREAD_RELATION_TYPE.name ? this.props.relation.event_id : undefined;
        return (
            <div className="mx_SendMessageComposer" onClick={this.focusComposer} onKeyDown={this.onKeyDown}>
                <BasicMessageComposer
                    onChange={this.onChange}
                    ref={this.editorRef}
                    model={this.model}
                    room={this.props.room}
                    threadId={threadId}
                    label={this.props.placeholder}
                    placeholder={this.props.placeholder}
                    onPaste={this.onPaste}
                    disabled={this.props.disabled}
                    onBlur={this.onBlur}
                />
            </div>
        );
    }
}

const SendMessageComposerWithMatrixClient = withMatrixClientHOC(SendMessageComposer);
export default SendMessageComposerWithMatrixClient;
