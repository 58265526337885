import { MatrixClient } from "matrix-js-sdk/src/client";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { EventType } from "matrix-js-sdk/src/@types/event";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import { ViewRoomPayload } from "matrix-react-sdk/src/dispatcher/payloads/ViewRoomPayload";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { ISendEventResponse } from "matrix-js-sdk/src/@types/requests";
import { ViewRoom as ViewRoomEvent } from "@matrix-org/analytics-events/types/typescript/ViewRoom";
import { IContent } from "matrix-js-sdk/src/matrix";

export async function unpinMessage(cli: MatrixClient, event: MatrixEvent): Promise<ISendEventResponse | void> {
    const room = cli.getRoom(event.getRoomId());
    const pinnedEvents = room?.currentState.getStateEvents(EventType.RoomPinnedEvents, "");
    if (pinnedEvents?.getContent()?.pinned) {
        const pinned = pinnedEvents.getContent().pinned;
        const index = pinned.indexOf(event.getId());
        if (index !== -1) {
            pinned.splice(index, 1);
            return await cli.sendStateEvent(room?.roomId ?? '', EventType.RoomPinnedEvents, { pinned }, "");
        }
    }
}

export function hasCaption(mxEvent: MatrixEvent): boolean {
    const content = mxEvent.getContent();
    return content.filename && (content.body || content.formatted_body)
        || !content.filename && content.description;
}

export function getCaptionBody(content: IContent): string {
    if (content.filename) {
        return content.body;
    }
    return content.description;
}

export function getCaptionEvent(event: MatrixEvent): MatrixEvent {
    const content = event.getContent();
    const fakeContent = {
        "msgtype": "m.text",
        "body": getCaptionBody(content),
        "format": undefined,
        "formatted_body": undefined,
    }
    if (content.formatted_body) {
        fakeContent.format = content.format;
        fakeContent.formatted_body = content.formatted_body;
    }
    return new MatrixEvent({
        sender: event.getSender(),
        event_id: event.getId(),
        room_id: event.getRoomId(),
        type: "m.room.message",
        content: fakeContent,
    });
}

export function containsCodeBlock(content: string): boolean {
    const codeBlockRegex = /```.*?```/gs;
    return codeBlockRegex.test(content);
}

export function addLanguageClassBasedOnText(message: string, richMessage: string): string {
    // Regular expression to find language and code blocks in message
    const codeBlockRegex = /```(\w+)<br>((.|\n)*?)(```|$)/g;

    // Extract the languages in order from message
    const languages: string[] = [];
    message.replace(codeBlockRegex, (match, language) => {
        languages.push(language); // Add the detected language to the array
        return match;
    });

    // Regular expression to match <code> tags in richMessage
    const codeTagRegex = /<code>/g;
    let languageIndex = 0;

    // Replace <code> tags in richMessage with appropriate language classes
    const updatedRichMessage = richMessage.replace(codeTagRegex, () => {
        // Check if a language exists at the current index
        if (languageIndex < languages.length) {
            const languageClass = ` class="language-${languages[languageIndex]}"`;
            languageIndex++; // Move to the next language for the next <code> block
            return `<code${languageClass}>`;
        }
        // Skip or return <code> without modification if no language is found
        return '<code>';
    });

    return cleanFormattedBody(updatedRichMessage);
}

function cleanFormattedBody(text: string): string {
    const unwantedPattern = /&lt;\/<\/code>/g;

    return text.replace(unwantedPattern, '');
}

export function dispatchToMessage(
    roomId: string,
    eventId: string,
    highlighted = false,
    metricsTrigger:string | undefined = undefined,
): void {
    if (roomId === undefined || eventId === undefined) {
        return;
    }
    dis.dispatch<ViewRoomPayload>({
        action: Action.ViewRoom,
        event_id: eventId,
        highlighted,
        room_id: roomId,
        metricsTrigger: metricsTrigger as ViewRoomEvent["trigger"],
    });
}

export function isStickerType(mxEvent: MatrixEvent): boolean {
    return mxEvent.getType() === "m.sticker";
}

