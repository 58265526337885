import React, { createRef, KeyboardEvent, SyntheticEvent } from "react";
import classNames from "classnames";
import AccessibleTooltipButton from "matrix-react-sdk/src/components/views/elements/AccessibleTooltipButton";
import RoomContext from "matrix-react-sdk/src/contexts/RoomContext";
import { MatrixClient, Room } from "matrix-js-sdk/src/matrix";
import { Caret } from "matrix-react-sdk/src/editor/caret";
import { IDiff } from "matrix-react-sdk/src/editor/diff";
import EditorModel from "matrix-react-sdk/src/editor/model";
import { CommandPartCreator, Part, SerializedPart } from "matrix-react-sdk/src/editor/parts";
import { htmlSerializeIfNeeded, textSerialize } from "matrix-react-sdk/src/editor/serialize";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { ICaption } from "@ctalk/interfaces/messages/IMessage";
import { getKeyBindingsManager } from "matrix-react-sdk/src/KeyBindingsManager";
import { KeyBindingAction } from "matrix-react-sdk/src/accessibility/KeyboardShortcuts";
import { IHistoryItems } from "@ctalk/managers/SendMessageHistoryManager";
import { logger } from "matrix-js-sdk/src/logger";

import { _t } from "../../../../languageHandler";
import BasicMessageComposer from "../../../../components/views/rooms/BasicMessageComposer";


interface IProps {
    compact?: boolean;
    sendMessage?: (caption: ICaption | undefined) => void;

    // CTalk added
    mxClient: MatrixClient;
    room?: Room;
}

interface IState {
    caption: string;
}

/*
enum KeyCode {
    Enter = 'Enter'
}
*/

export default class CaptionComposer extends React.Component<IProps, IState> {
    private ref: React.RefObject<HTMLDivElement> = createRef();
    private readonly editorRef = createRef<BasicMessageComposer>();
    public static contextType = RoomContext;
    public context!: React.ContextType<typeof RoomContext>;
    private model: EditorModel;
    public static defaultProps = {
        compact: false,
    };

    public constructor(props: IProps) {
        super(props);
        this.state = {
            caption: '',
        };
        this.initModel();
    }

    private initModel(): void {
        const key = `mx_cider_state_${this.props.room?.roomId}`;
        const partCreator = new CommandPartCreator(this.props.room!, this.props.mxClient);
        const json = localStorage.getItem(key);
        if (json) {
            try {
                /* CTalk hide this
                const { parts: serializedParts, replyEventId } = JSON.parse(json);
                const parts: Part[] = serializedParts.map((p: SerializedPart) => partCreator.deserializePart(p));
                if (replyEventId) {
                    dis.dispatch({
                        action: "reply_to_event",
                        event: this.props.room.findEventById(replyEventId),
                        context: this.context.timelineRenderingType,
                    });
                }
                 */
                const currentItems = JSON.parse(json) as IHistoryItems;
                const { base } = currentItems;
                const serializedParts = base?.parts ?? [];
                const parts: Part[] = serializedParts
                    .map((p: SerializedPart) => partCreator.deserializePart(p))
                    .filter((part): part is Part => part !== undefined);
                this.model = new EditorModel(parts, partCreator);

            } catch (e) {
                logger.error(e);
            }

        } else {
            this.model = new EditorModel([], partCreator);
        }
    }



    private sendMessage = async (): Promise<void> => {
        // Do something here
        // then call props sendMessage()
        if (typeof this.props.sendMessage === 'function') {
            const caption = this.getCaption();
            this.props.sendMessage(caption);
        }
    };

    private getCaption(): ICaption | undefined {
        const body = textSerialize(this.model);
        if (!body) {
            return;
        }
        const formattedBody = htmlSerializeIfNeeded(this.model, {
            forceHTML: true,
            useMarkdown: SettingsStore.getValue("MessageComposerInput.useMarkdown"),
        });
        return {
            body,
            formattedBody,
        }
    }

    /*
    private onKeypress = (event: KeyboardEvent): void => {
        if (event.code === KeyCode.Enter && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
            return;
        }
    };

    private onInput = (event: React.FormEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();
        // set auto height
        const element = event.currentTarget as HTMLTextAreaElement;
        element.style.height = '25px'; // auto minimize
        element.style.height = `${element.scrollHeight}px`; // auto maximize
        this.setState({
            caption: element.value,
        });
    };
     */

    private onChange = (selection?: Caret, inputType?: string, diff?: IDiff): void => {};

    private onKeyDown = (event: KeyboardEvent): void => {
        if (this.editorRef.current?.isComposing(event)) {
            return;
        }
        const action = getKeyBindingsManager().getMessageComposerAction(event);
        if (action === KeyBindingAction.SendMessage) {
            this.sendMessage();
            event.preventDefault();
        }
    }

    private onPaste = (event: Event | SyntheticEvent, data: DataTransfer): boolean => {
        //
        return false;
    }

    public render(): React.ReactNode {
        const classes = classNames({
            "mx_MessageComposer": true,
            "mx_MessageComposer--compact": this.props.compact,
        });
        return (
            <div className={classes} ref={this.ref}>
                {/*
                <div className="ctalk_MessageComposer_wrapper">
                    <div className="mx_MessageComposer_row">
                        <div className="mx_SendMessageComposer">
                            <div className="mx_BasicMessageComposer">
                                <textarea
                                    autoFocus
                                    value={this.state.caption}
                                    style={{ height: '25px' }}
                                    className="mx_BasicMessageComposer_input"
                                    maxLength={1024}
                                    placeholder={_t('CTALK_ADD_A_CAPTION')}
                                    onInput={this.onInput}
                                    onKeyPress={this.onKeypress}
                                />
                            </div>
                        </div>
                        <AccessibleTooltipButton
                            key="controls_send"
                            className="mx_MessageComposer_sendMessage"
                            onClick={this.sendMessage}
                            title={_t('ctalk|action|send')}
                        />
                    </div>
                </div>
                */}
                <div className="ck_MessageComposer_wrapper">
                    <div className="mx_MessageComposer_row">
                        <div className="mx_SendMessageComposer" onKeyDown={this.onKeyDown}>
                            <BasicMessageComposer
                                onChange={this.onChange}
                                ref={this.editorRef}
                                model={this.model}
                                room={this.props.room!}
                                threadId={undefined}
                                label={_t('CTALK_ADD_A_CAPTION')}
                                placeholder={_t('CTALK_ADD_A_CAPTION')}
                                onPaste={this.onPaste}
                            />
                        </div>
                        <div className="mx_MessageComposer_actions">
                            <AccessibleTooltipButton
                                className="mx_MessageComposer_sendMessage"
                                onClick={this.sendMessage}
                                title={_t('ctalk|action|send')}
                                data-testid="sendmessagebtn"
                            />
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}
